export default {
  kaporal_fr_de: [
    {
      title: "APIs",
      description:
        "APIs werden benutzt um Skripte zu laden, wie: Geolokalisierung, Suchmaschinen, Übersetzungen, ...",
      services: [],
    },
    {
      title: "Marketing",
      description:
        "Unsere Werbepartner verwenden Cookies, um relevante Anzeigen zu schalten, die unsere Seite unterstützen. Sie sammeln Informationen über Ihre Online-Aktivitäten, wie die Seiten, die Sie besuchen, und die Links, auf die Sie klicken, um Ihnen Werbung anzubieten, die Ihren Interessen entspricht.",
      services: [
        {
          name: "bing",
          title: "Bing",
          cookies: [
            "MSPTC",
            "MUID",
            "_uetsid",
            "_uetvid",
          ],
          description: "Sammelt Daten, um personalisierte Anzeigen basierend auf Ihren vorherigen Besuchen und Suchen anzuzeigen.",
          link: "",
        },
        {
          name: "criteostatic",
          title: "Criteo",
          cookies: [
            "cto_bundle",
            "uid",
          ],
          description: "Verwendet Cookies, um Ihnen personalisierte Anzeigen basierend auf Ihrem Surfverhalten auf unserer Seite und anderswo im Internet zu zeigen.",
          link: "",
        },
        {
          name: "facebookpixel",
          title: "Facebook Pixel",
          cookies: [
            "datr",
            "fr",
            "reg_ext_ref",
            "reg_fb_gate",
            "reg_fb_ref",
            "sb",
            "wd",
            "x-src",
            "_fbp",
            "_fbc",
          ],
          description: "Hilft dabei, Werbekampagnen auf Facebook zu messen, zu optimieren und Zielgruppen aufzubauen, basierend auf den Seiten, die Sie besucht haben.",
          link: "https://fr-fr.facebook.com/business/help/www/651294705016616",
        },
        {
          name: "googleads",
          title: "Google Ads",
          cookies: [
            "AEC",
            "DSID",
            "IDE",
            "NID",
            "SOCS",
            "_gcl_au",
          ],
          description: "Verwendet Cookies, um Anzeigen basierend auf Ihren früheren Besuchen auf unserer Seite und anderen Websites anzuzeigen.",
          link: "",
        },
        {
          name: "tiktok",
          title: "Tiktok ads",
          cookies: [],
          description: "Sammelt Daten über Ihre Interaktion mit unserer Seite, um Ihnen personalisierte Anzeigen auf der TikTok-Plattform anzubieten.",
          link: "",
        },
      ],
    },
    {
      title: "Leistung & Analyse Web",
      description:
        "Wir verwenden Cookies, um anonyme Statistiken zu sammeln, die uns helfen zu verstehen, wie Besucher mit unserer Seite interagieren. Diese Daten ermöglichen es uns, Ihre Benutzererfahrung kontinuierlich zu verbessern.",
      services: [
        {
          name: "googleuseranalytics",
          title: "Google Analytics",
          cookies: [
            "_ga",
            "_gat",
            "_gid",
            "__utma",
            "__utmb",
            "__utmc",
            "__utmt",
            "__utmz",
            "_ga_PYKHXW9M3Z",
            "EUULE",
            "UULE",
            "_dc_gtm_UA-3364108-2",
          ],
          description: "Sammelt anonyme Informationen darüber, wie Besucher unsere Seite nutzen, um uns zu helfen, Benutzerverhalten zu verstehen und unsere Webseite zu verbessern.",
          link: "https://support.google.com/analytics/answer/6004245",
        },
        {
          name: "actito",
          title: "Actito",
          cookies: [],
          description: "Sammelt Leistungsdaten, um das Benutzererlebnis zu personalisieren und die Effektivität von Marketingkampagnen zu erhöhen.",
          link: "",
        },
        {
          name: "adobeStoreFrontEvent",
          title: "Adobe Products Recommendation event",
          cookies: [
            "mg_dnt",
            "user_allowed_save_cookie",
            "authentication_flag",
            "dataservices_customer_id",
            "dataservices_cart_id",
            "dataservices_product_context",
          ],
          description: "Verwendet Cookies, um Ihre Einkaufspräferenzen und -verhalten zu analysieren, um Produkte zu empfehlen, die Sie interessieren könnten.",
          link: "",
        },
      ],
    },
    {
      title: "Anpassung",
      description: "Unsere Partner im Bereich Personalisierung verwenden Cookies, um den Inhalt unserer Seite basierend auf Ihren vergangenen Interaktionen zu gestalten, wodurch Ihr Surf-Erlebnis relevanter und persönlicher wird.",
      services: [
        {
          name: "onestock",
          title: "Onestock",
          cookies: [],
          description: "Verwendet Cookies, um Inhalte und Angebote auf unserer Seite zu personalisieren, basierend auf Ihrem Surfverhalten und Einkaufspräferenzen, um Ihr Online-Shopping-Erlebnis zu verbessern.",
          link: "",
        },
      ],
    },
  ],
  kaporal_fr_en: [
    {
      title: "APIs",
      description:
        "APIs are used to load scripts: geolocation, search engines, translations, ...",
      services: [],
    },
    {
      title: "Marketing",
      description:
        "Our advertising partners use cookies to serve relevant ads that support our site. They collect information about your online activities, such as the pages you visit and the links you click, to offer you advertisements that align with your interests.",
      services: [
        {
          name: "bing",
          title: "Bing",
          cookies: [
            "MSPTC",
            "MUID",
            "_uetsid",
            "_uetvid",
          ],
          description:
            "Collects data to display personalized ads based on your past visits and searches.",
          link: "",
        },
        {
          name: "criteostatic",
          title: "Criteo",
          cookies: [
            "cto_bundle",
            "uid",
          ],
          description: "Uses cookies to show you personalized ads based on your browsing history on our site and elsewhere on the Internet.",
          link: "",
        },
        {
          name: "facebookpixel",
          title: "Facebook Pixel",
          cookies: [
            "datr",
            "fr",
            "reg_ext_ref",
            "reg_fb_gate",
            "reg_fb_ref",
            "sb",
            "wd",
            "x-src",
            "_fbp",
            "_fbc",
          ],
          description: "Helps measure, optimize, and build audiences for advertising campaigns on Facebook based on the pages you have visited.",
          link: "https://fr-fr.facebook.com/business/help/www/651294705016616",
        },
        {
          name: "googleads",
          title: "Google Ads",
          cookies: [
            "AEC",
            "DSID",
            "IDE",
            "NID",
            "SOCS",
            "_gcl_au",
          ],
          description: "Uses cookies to display ads based on your previous visits to our site and other websites.",
          link: "",
        },
        {
          name: "tiktok",
          title: "Tiktok ads",
          cookies: [],
          description: "Collects data about your interaction with our site to offer you personalized ads on the TikTok platform.",
          link: "",
        },
      ],
    },
    {
      title: "Performance & web analysis",
      description:
        "We use cookies to collect anonymous statistics that help us understand how visitors interact with our site. This data allows us to continuously improve your user experience.",
      services: [
        {
          name: "googleuseranalytics",
          title: "Google Analytics",
          cookies: [
            "_ga",
            "_gat",
            "_gid",
            "__utma",
            "__utmb",
            "__utmc",
            "__utmt",
            "__utmz",
            "_ga_PYKHXW9M3Z",
            "EUULE",
            "UULE",
            "_dc_gtm_UA-3364108-2",
          ],
          description: "Gathers anonymous information on how visitors use our site, helping us understand user behaviors and improve our website.",
          link: "https://support.google.com/analytics/answer/6004245",
        },
        {
          name: "actito",
          title: "Actito",
          cookies: [],
          description: "Collects performance data to customize the user experience and enhance marketing campaign effectiveness.",
          link: "",
        },
        {
          name: "adobeStoreFrontEvent",
          title: "Adobe Products Recommendation event",
          cookies: [
            "mg_dnt",
            "user_allowed_save_cookie",
            "authentication_flag",
            "dataservices_customer_id",
            "dataservices_cart_id",
            "dataservices_product_context",
          ],
          description: "Uses cookies to analyze your shopping preferences and behaviors to recommend products that might interest you.",
          link: "",
        },
      ],
    },
    {
      title: "Customization",
      description: "Our personalization partners use cookies to tailor the content of our site based on your past interactions, making your browsing experience more relevant and personal.",
      services: [
        {
          name: "onestock",
          title: "Onestock",
          cookies: [],
          description: "",
          link: "",
          description: "",
          link: "",
        },
        {
          name: "contentsquare",
          title: "Contentsquare",
          cookies: [],
          description: "",
          link: "",
        },
      ],
    },
  ],
  kaporal_fr_es: [
    {
      title: "APIs",
      description:
        "APIs se utilizan para cargar scripts: geolocalización, motor de búsqueda, traducciones, ...",
      services: [],
    },
    {
      title: "Marketing",
      description:
        "Nuestros socios publicitarios utilizan cookies para ofrecer anuncios relevantes que apoyan nuestro sitio. Recopilan información sobre sus actividadesen línea, como las páginas que visita y los enlaces en los que hace clic, para ofrecerle anuncios que se alinean con sus intereses.",
      services: [
        {
          name: "bing",
          title: "Bing",
          cookies: [
            "MSPTC",
            "MUID",
            "_uetsid",
            "_uetvid",
          ],
          description: "Recopila datos para mostrar anuncios personalizados basados en sus visitas y búsquedas anteriores.",
          link: "",
        },
        {
          name: "criteostatic",
          title: "Criteo",
          cookies: [
            "cto_bundle",
            "uid",
          ],
          description: "Usa cookies para mostrarle anuncios personalizados basados en su historial de navegación en nuestro sitio y en otros lugares de Internet.",
          link: "",
        },
        {
          name: "facebookpixel",
          title: "Facebook Pixel",
          cookies: [
            "datr",
            "fr",
            "reg_ext_ref",
            "reg_fb_gate",
            "reg_fb_ref",
            "sb",
            "wd",
            "x-src",
            "_fbp",
            "_fbc",
          ],
          description: "Ayuda a medir, optimizar y construir audiencias para campañas publicitarias en Facebook basadas en las páginas que ha visitado.",
          link: "https://fr-fr.facebook.com/business/help/www/651294705016616",
        },
        {
          name: "googleads",
          title: "Google Ads",
          cookies: [
            "AEC",
            "DSID",
            "IDE",
            "NID",
            "SOCS",
            "_gcl_au",
          ],
          description: "Usa cookies para mostrar anuncios basados en sus visitas anteriores a nuestro sitio y otros sitios web.",
          link: "",
        },
        {
          name: "tiktok",
          title: "Tiktok ads",
          cookies: [],
          description: "Recopila datos sobre su interacción con nuestro sitio para ofrecerle anuncios personalizados en la plataforma TikTok.",
          link: "",
        },
      ],
    },
    {
      title: "Rendimiento y análisis web",
      description: "Usamos cookies para recopilar estadísticas anónimas que nos ayudan a entender cómo los visitantes interactúan con nuestro sitio. Estos datos nos permiten mejorar continuamente su experiencia de usuario.",
      services: [
        {
          name: "googleuseranalytics",
          title: "Google Analytics",
          cookies: [
            "_ga",
            "_gat",
            "_gid",
            "__utma",
            "__utmb",
            "__utmc",
            "__utmt",
            "__utmz",
            "_ga_PYKHXW9M3Z",
            "EUULE",
            "UULE",
            "_dc_gtm_UA-3364108-2",
          ],
          description: "Recopila información anónima sobre cómo los visitantes usan nuestro sitio, ayudándonos a entender los comportamientos de los usuarios y a mejorar nuestro sitio web.",
          link: "https://support.google.com/analytics/answer/6004245",
        },
        {
          name: "actito",
          title: "Actito",
          cookies: [],
          description: "Recopila datos de rendimiento para personalizar la experiencia del usuario y mejorar la eficacia de las campañas de marketing.",
          link: "",
        },
        {
          name: "adobeStoreFrontEvent",
          title: "Adobe Products Recommendation event",
          cookies: [
            "mg_dnt",
            "user_allowed_save_cookie",
            "authentication_flag",
            "dataservices_customer_id",
            "dataservices_cart_id",
            "dataservices_product_context",
          ],
          description: "Utiliza cookies para analizar sus preferencias y comportamientos de compra para recomendarle productos que podrían interesarle.",
          link: "",
        },
      ],
    },
    {
      title: "Personalización",
      description: "Nuestros socios de personalización usan cookies para adaptar el contenido de nuestro sitio basado en sus interacciones pasadas, haciendo su experiencia de navegación más relevante y personal.",
      services: [
        {
          name: "onestock",
          title: "Onestock",
          cookies: [],
          description: "Usa cookies para personalizar el contenido y las ofertas en nuestro sitio, basado en su historial de navegación y preferencias de compra, para mejorar su experiencia de compra en línea.",
          link: "",
        },
      ],
    },
  ],
  kaporal_fr_fr: [
    {
      title: "APIs",
      description:
        "Les APIs permettent de charger des scripts : géolocalisation, moteurs de recherche, traductions, ...",
      services: [],
    },
    {
      title: "Marketing",
      description:
        "Nos partenaires publicitaires utilisent des cookies pour diffuser des annonces pertinentes qui soutiennent notre site. Ils collectent des informations sur vos activités en ligne, telles que les pages visitées et les liens cliqués, pour vous proposer des publicités en accord avec vos intérêts.",
      services: [
        {
          name: "bing",
          title: "Bing",
          cookies: [
            "MSPTC",
            "MUID",
            "_uetsid",
            "_uetvid",
          ],
          description: "Collecte des données pour afficher des publicités personnalisées en fonction de vos précédentes visites et de vos recherches.",
          link: "",
        },
        {
          name: "criteostatic",
          title: "Criteo",
          cookies: [
            "cto_bundle",
            "uid",
          ],
          description: "Utilise des cookies pour vous montrer des annonces personnalisées basées sur votre historique de navigation sur notre site et ailleurs sur Internet.",
          link: "",
        },
        {
          name: "facebookpixel",
          title: "Facebook Pixel",
          cookies: [
            "datr",
            "fr",
            "reg_ext_ref",
            "reg_fb_gate",
            "reg_fb_ref",
            "sb",
            "wd",
            "x-src",
            "_fbp",
            "_fbc",
          ],
          description: "Aide à mesurer, optimiser et construire des audiences pour des campagnes publicitaires sur Facebook en fonction des pages que vous avez visitées.",
          link: "https://fr-fr.facebook.com/business/help/www/651294705016616",
        },
        {
          name: "googleads",
          title: "Google Ads",
          cookies: [
            "AEC",
            "DSID",
            "IDE",
            "NID",
            "SOCS",
            "_gcl_au",
          ],
          description: "Utilise des cookies pour afficher des publicités basées sur vos visites précédentes sur notre site et d'autres sites web.",
          link: "",
        },
        {
          name: "tiktok",
          title: "Tiktok ads",
          cookies: [],
          description: "Collecte des données sur votre interaction avec notre site pour vous proposer des publicités personnalisées sur la plateforme TikTok.",
          link: "",
        },
      ],
    },
    {
      title: "Performance & analyse web",
      description:
        "Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles à l'amélioration du site.",
      services: [
        {
          name: "googleuseranalytics",
          title: "Google Analytics",
          cookies: [
            "_ga",
            "_gat",
            "_gid",
            "__utma",
            "__utmb",
            "__utmc",
            "__utmt",
            "__utmz",
            "_ga_PYKHXW9M3Z",
            "EUULE",
            "UULE",
            "_dc_gtm_UA-3364108-2",
          ],
          description: "Recueille des informations anonymes sur la façon dont les visiteurs utilisent notre site, nous aidant à comprendre les comportements des utilisateurs et à améliorer notre site.",
          link: "https://support.google.com/analytics/answer/6004245",
        },
        {
          name: "actito",
          title: "Actito",
          cookies: [],
          description: "Collecte des données de performance pour personnaliser l'expérience utilisateur et améliorer l'efficacité des campagnes marketing.",
          link: "",
        },
        {
          name: "adobeStoreFrontEvent",
          title: "Adobe Products Recommendation event",
          cookies: [
            "mg_dnt",
            "user_allowed_save_cookie",
            "authentication_flag",
            "dataservices_customer_id",
            "dataservices_cart_id",
            "dataservices_product_context",
          ],
          description: "Utilise des cookies pour analyser vos préférences et comportements d'achat afin de vous recommander des produits susceptibles de vous intéresser.",
          link: "",
        },
      ],
    },
    {
      title: "Personnalisation",
      description: "Nos partenaires en personnalisation utilisent des cookies pour adapter l'affichage du contenu de notre site en fonction de vos interactions passées, rendant votre expérience de navigation plus pertinente et personnelle.",
      services: [
        {
          name: "onestock",
          title: "Onestock",
          cookies: [],
          description: "Utilise des cookies pour personnaliser le contenu et les offres sur notre site, en fonction de votre historique de navigation et de vos préférences d'achat, afin d'améliorer votre expérience de shopping en ligne.",
          link: "",
        },
      ],
    },
  ],
};
